.image-card {
    position: relative;
    width: 300px;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    margin-top: 20px;
    margin-right: 20px;
  }
  
  .card-image {
    width: 100%;
    height: auto;
  }
  
  .image-card-container {
    display: flex;
    flex-wrap: wrap;
  }
