.login_logo {
  height: 50px;
  position: absolute;
  width: 230px;
  top: 28px;
  left: 28px;
}

.welcome {
  width: 191px;
  height: 36px;
  top: 120px;
  left: 34px;
  position: absolute;
  color: #232629;
}

.login_subheading {
  color: #979797;
  width: 285px;
  height: 21px;
  top: 165px;
  left: 34px;
  position: absolute;
}

.l_email_label {
  display: block;
  width: 39px;
  height: 21px;
  top: 222px;
  left: 33px;
  position: absolute;
  color: #232629;
}

.l_email_input {
  width: 300px;
  height: 20px;
  top: 249px;
  left: 33px;
  padding: 8px 10px 8px 14px;
  border-radius: 4px;
  border: 2px solid #f1f1f1;
  position: absolute;
  outline: none;
}


.l_pass_label {
  display: block;
  width: 69px;
  height: 21px;
  top: 308px;
  left: 33px;
  position: absolute;
  color: #232629;
}

.l_pass_input {
  width: 300px;
  height: 20px;
  top: 335px;
  left: 33px;
  padding: 8px 10px 8px 14px;
  border-radius: 4px;
  border: 2px solid #f1f1f1;
  position: absolute;
  outline: none;
}

.l_span {
  width: 135px;
  height: 21px;
  top: 395px;
  left: 36px;
  position: absolute;
}

.l_checkbox {
  top: 8%;
  left: -3.275390625px;
  position: absolute;
  border-radius: 4px;
}
/* .l_checkbox:checked::after {
  background-color: green;
} */

.text_checkbox {
  width: 107px;
  height: 21px;
  left: 12%;
  position: absolute;
}

.login_btn {
  width: 100px;
  height: 40px;
  top: 434px;
  left: 34px;
  padding: 10px;
  border-radius: 4px;
  gap: 4px;
  position: absolute;
  border: none;
  outline: none;
  background: #00af80;
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;

  /* text-decoration: white; */
}

.login_with {
  width: 88px;
  height: 21px;
  top: 464px;
  left: 33px;
  position: absolute;
  color: #232629;

}

.l_social_icons {
  position: absolute;
  top: 520px;
}

.l_facebook {
  width: 40px;
  height: 40px;
  left: 33px;
  position: absolute;
}

.l_linkedin {
  width: 40px;
  height: 40px;
  left: 83px;
  position: absolute;
}

.l_twitter {
  width: 40px;
  height: 40px;
  left: 133px;
  position: absolute;
}

.l_gmail {
  width: 40px;
  height: 40px;
  left: 183px;
  position: absolute;
}

.signup_now {
  top: 557px;
  left: 33px;
  position: absolute;
  color: #232629;

}

.forget_pass{
  top: 584px;
  left: 33px;
  position: absolute;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #232629;

}

.footer_img{
top: 739px;
left: 42.75px;
position: absolute;
}

.copyright{
top: 933px;
left: 33px;
position : absolute;
color: #232629;

}

.login_bg{
height: auto;
width: auto;
left: 403px;
padding: 0px 1px 0px 0px;
position: absolute;
}

.login_bg_heading
{
width: 653px;
height: 48px;
top: 764px;
left: 683px;
font-size: 32px;
font-style: italic;
font-weight: 600;
line-height: 48px;
letter-spacing: 0em;
text-align: left;
color: #FFFFFF;
position: absolute;
}

.login_bg_subheading{
width: 742px;
height: 115px;
font-size: 14px;
font-weight: 400;
line-height: 23px;
letter-spacing: 0.01em;
text-align: center;
top: 827px;
left: 605px;
position: absolute;
color: #FFFFFF;
}