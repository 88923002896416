.register_logo {
    height: 50px;
    position: absolute;
    width: 230px;
    top: 28px;
    left: 28px;
  }
  
  .register {
    width: 191px;
    height: 36px;
    top: 120px;
    left: 34px;
    position: absolute;
    color: #232629;
  }
  
  .register_subheading {
    color: #979797;
    width: 310px;
    height: 21px;
    top: 165px;
    left: 34px;
    position: absolute;
  }

  .r_fname_label{
    width: 76px;
    height: 20px;
    top: 222px;
    left: 33px;
    position: absolute;
    color: #232629;
  }

  .r_fname_input{
    width: 125px;
    height: 20px;
    top: 249px;
    left: 33px;
    padding: 8px 10px 8px 14px;
    border-radius: 4px;
    border: 2px solid #f1f1f1;
    position: absolute;
    outline: none;
  }

  .r_lname_label{
    width: 76px;
    height: 20px;
    top: 222px;
    left: 210px;
    position: absolute;
    color: #232629;
  }

  .r_lname_input{
    width: 125px;
    height: 20px;
    top: 249px;
    left: 210px;
    padding: 8px 10px 8px 14px;
    border-radius: 4px;
    border: 2px solid #f1f1f1;
    position: absolute;
    outline: none;
  }


  .r_email_label {
    display: block;
    width: 39px;
    height: 21px;
    top: 308px;
    left: 33px;
    position: absolute;
    color: #232629;
  }
  
  .r_email_input {
    width: 300px;
    height: 20px;
    top: 335px;
    left: 33px;
    padding: 8px 10px 8px 14px;
    border-radius: 4px;
    border: 2px solid #f1f1f1;
    position: absolute;
    outline: none;
  }
  
  .r_pass_label {
    display: block;
    width: 69px;
    height: 21px;
    top: 394px;
    left: 33px;
    position: absolute;
    color: #232629;
  }
  
  .r_pass_input {
    width: 300px;
    height: 20px;
    top: 421px;
    left: 33px;
    padding: 8px 10px 8px 14px;
    border-radius: 4px;
    border: 2px solid #f1f1f1;
    position: absolute;
    outline: none;
  }
  
  .register_btn {
    width: 100px;
    height: 40px;
    top: 480px;
    left: 34px;
    padding: 10px;
    border-radius: 4px;
    gap: 4px;
    position: absolute;
    border: none;
    outline: none;
    background: #00af80;
    color: #ffffff;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
  
    /* text-decoration: white; */
  }
  
  .register_with {
    width: 100px;
    height: 21px;
    top: 515px;
    left: 33px;
    position: absolute;
    color: #232629;
  
  }
  
  .r_social_icons {
    position: absolute;
    top: 569px;
  }
  
  .r_facebook {
    width: 40px;
    height: 40px;
    left: 33px;
    position: absolute;
  }
  
  .r_linkedin {
    width: 40px;
    height: 40px;
    left: 83px;
    position: absolute;
  }
  
  .r_twitter {
    width: 40px;
    height: 40px;
    left: 133px;
    position: absolute;
  }
  
  .r_gmail {
    width: 40px;
    height: 40px;
    left: 183px;
    position: absolute;
  }
  
  .login_now {
    top: 604px;
    left: 33px;
    position: absolute;
    color: #232629;
  
  }
  
  .term_of_use{
    top: 633px;
    left: 33px;
    position: absolute;
    color: #232629;
  
  }
  
  /* .footer_img{
  top: 713px;
  left: 42.75px;
  position: absolute;
  } */
  
  .r_copyright{
  top: 806px;
  left: 33px;
  position : absolute;
  color: #232629;
  
  }
  
  /* .login_bg{
  height: auto;
  width: auto;
  left: 403px;
  padding: 0px 1px 0px 0px;
  position: absolute;
  }
  
  .login_bg_heading
  {
  
  top: 764px;
  left: 683px;
  font-size: 32px;
  font-style: italic;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: left;
  color: #FFFFFF;
  position: absolute;
  } */