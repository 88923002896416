body {
  margin: 0;
  font-family: 'Poppins';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f7f7f7;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.heading1{
font-size: 32px;
font-weight: 600;
line-height: 48px;
letter-spacing: 0em;
text-align: left;
}

.heading2{
font-size: 24px;
font-weight: 600;
line-height: 36px;
letter-spacing: 0em;
text-align: left;
}

.heading3{
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
}

.heading4{
font-size: 14px;
font-weight: 400;
line-height: 21px;
letter-spacing: 0em;
text-align: left;
}


.heading5{
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  }

.heading6{
font-family: Plus Jakarta Sans;
font-size: 10px;
font-weight: 500;
line-height: 13px;
letter-spacing: 0em;
text-align: left;
}

.text{
  color: #232629;
}

.text_color2{
  color: #808080;
}

.primary{
  color: #08192B;

}
.secondary{
  color: #00AF80;
}

.cta{
  color: #F69110;
}

.heading_div{
  display: flex;
  left: 25px;
  position: absolute;
  width: 100%;
}

.dashboard{
  margin-left: 205px;
  padding: 0px 20px;
}

.dashboard_heading {
  font-size: 22px;
  font-weight: 600;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
}

.dashboard_subheading {
  display: flex;
}

.div_container {
  background: white;
  min-height: 300px;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid var(--border);
}

.add_flight_btn {
  width: 100px;
  height: 24px;
  background: #00af80;
  border: none;
  border-radius: 4px;
  color: white;
}

.add_flight_btn:hover{
    background: #2BC48A;

}

.add_aircraft_btn {
  width: 100px;
  height: 24px;
  background: #f69110;
  border: none;
  border-radius: 4px;
  color: white;
}

.add_aircraft_btn:hover{
  background: #FCA120;
}



.filter_btn {
  width: 100px;
  height: 24px;
  background: transparent;
  border: none;
  border-radius: 4px;
  border: 1px solid #808080;
  color : #808080;
}
