.angle_right{
    transform: rotate(90deg);
    position: relative;
    top: 0px;
    margin-right: 10px;
    font-size: 16px;
    padding: 4px;
    border-radius: 5px;
    margin-bottom: -5px;
    border: 1px solid var(--border)
}
.edit_dots{
    position: relative;
    top: 0px;
    margin-right: 10px;
    font-size: 14px;
    border-radius: 5px;
    margin-bottom: -5px;
}
.fleet_titles{
    margin-bottom: 10px;
}
.today_fleets{
    margin-bottom: 30px;
}
.header_th{
    display: flex;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #DDEBFC;
}
.header_th > div{
    width: 100%;
    font-size: 12px;
    font-weight: 600;
}
  
.header_td{
    display: flex;
    width: 100%;
    border-bottom: 1px solid #DDEBFC;
    padding:8px 0px;
}
.header_td > div{
    width: 100%;
    font-size: 14px;
}
.header_th > div:nth-child(1),
.header_td > div:nth-child(1){
    width: 200%;
}

.header_th > div:nth-child(4),
.header_td > div:nth-child(4){
    width: 120%;
}

.header_th > div:nth-child(8),
.header_td > div:nth-child(8){
    width: 120%;
}

.header_th > div:nth-child(9),
.header_td > div:nth-child(9){
    width: 120%;
}

.header_th > div:nth-child(10),
.header_td > div:nth-child(10){
    width: 120%;
}

/* .header_th > div:nth-child(2),
.header_td > div:nth-child(2){
    width: 13%;
}
.header_th > div:nth-child(3),
.header_td > div:nth-child(3){
    width: 14%;
}
.header_th > div:nth-child(4),
.header_td > div:nth-child(4){
    width: 23%;
}
.header_th > div:nth-child(5),
.header_td > div:nth-child(5){
    width: 23%;
}
.header_th > div:nth-child(6),
.header_td > div:nth-child(6){
    width: 10%;
}
.header_th > div:nth-child(7),
.header_td > div:nth-child(7){
    width: 5%;
    text-align: right;
} */
  
  
.dots{
    width: 10px;
    cursor: pointer;
}
  
.edit_menu{
    display: inline-block;
}
  
.edit_menu_ul{
    z-index: 20;
    list-style: none;
    text-align: left;
    background: white;
    padding: 8px;
    font-size: 12px;
    border-radius: 5px;
    box-shadow: 0px 2px 5px -2px rgb(0, 0, 0, 0.3);
    min-width: 40px;
    position: absolute
}
.edit_menu_ul li{
    padding: 4px;
}
.edit_menu_ul li a{
    color: black;
    text-decoration: none;
}
.edit_menu_ul li:nth-child(3) a{
    color: red;
}



.allflights{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }


