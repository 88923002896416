.btn_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flight_heading {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0.01em;
  text-align: left;
  text-decoration: underline;
}

.flight_subheading {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    width:100%;
}

.flight_details{
    width: 300px;
    display: flex;
    justify-content: space-between;
}

.flight_sections{
    border: 1px solid #DDEBFC;
    padding: 10px;
    border-radius: 8px;
}

.crew_div{
    display: flex;
    align-items: center;
    gap: 1%;
}