.plane_section {
  display: flex;
  border: 1px solid #efefef;
  border-radius: 6px;
  padding: 10px;
  margin-top: 20px;
  width: 200px;
  height: 90px;
  box-shadow: 0px 4px 10px 0px #0000000a;
}

.btn_list {
  width: 100%;
  box-shadow: 0px 4px 10px 0px #0000000a;
  border: 1px solid #efefef;
  list-style: none;
  border-radius: 6px;
}

.flight_btn {
  width: 120px;
  height: 24px;
  background: #00af80;
  border: none;
  border-radius: 4px;
  color: white;
}

.flight_btn:hover{
    background: #2BC48A;

}

.aircraft_btn {
  width: 120px;
  height: 24px;
  background: #f69110;
  border: none;
  border-radius: 4px;
  color: white;
}

.aircraft_btn:hover{
  background: #FCA120;
}

.airport_btn {
  width: 120px;
  height: 24px;
  background: #22389b;
  border: none;
  border-radius: 4px;
  color: white;
}

.airport_btn:hover{
  background: rgb(55, 72, 146);
}
.import_btn {
  width: 120px;
  height: 24px;
  background: #0e2946;
  border: none;
  border-radius: 4px;
  color: white;
}

.import_btn:hover{
    background: rgb(4, 50, 99);
}

.map_section {
  border: 1px solid #efefef;
  box-shadow: 0px 4px 10px 0px #0000000a;
  border-radius: 6px;
  width: 495px;
}
