.side_menu{
    position: absolute;
    width: 205px;
    height: 950px;
/* top: 65px */
padding: 5px 0px 15px 0px;
gap: 10px;

}

.logbook_heading{
    color: rgb(255, 255, 255);
    padding-left: 20px;
    display: flex;
    gap: 50%;
}


.main_menu{
    list-style: none;
    padding: 0 10px 5px 10px;
}
.main_menu li{
    padding: 15px;
}

.main_menu li:hover{
    background: #FFFFFF26;
}


.main_menu li a{
    color: #c5cae0;
    text-decoration: none;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.5px;
    display: block;
}

.main_menu li span{
    color: #c5cae0;
    text-decoration: none;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.5px;
    display: block;
}

.main_menu li:hover span{
    color:white;
}

.main_menu li:hover a{
    color:white;
}

.header_step {
    margin-top: 5px;
    font-size: 12px;
    font-weight: 500;
    padding: 10px 20px;
    color: #969696;
}

.header_step:before{
    content: "";
    display: block;
    width: 8px;
    height: 1px;
    background:#969696;
    position: relative;
    top: 8px;
    left: -14px;
}

/* .sub_menu{
    list-style: none;
    padding: 0 10px 5px 10px;
}
.sub_menu li{
    padding: 10px;
}

.sub_menu li a{
    color: #c5cae0;
    text-decoration: none;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.5px;
    display: block;
}
.sub_menu li:hover a{
    color:white;
} */
.clear_icon{
    display: flex;
    justify-content: flex-end;
    width: 95%;
}

.add_group{
    border: 1px solid #dee2e6!important;
    margin: 0;
    padding: 10px 20px;
    background: white;
    width: 30%;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    border-radius: 4px;
}

.add_input{
    margin-left: 10px;
    height: 0px;
    padding: 8px 10px 8px 14px;
    border: none;
    outline: none;
    width: 70%;
}
.accept_terms{
    border: 1px solid #dee2e6!important;
    margin: 0;
    padding: 20px 20px;
    background: white;
    width: 35%;
    display: flex;
    align-items: center;
    margin-bottom: 3rem;
    margin-top: 1rem;
    gap:3%;
    border-radius: 4px;
}

.f-flex{
    display: flex;
    gap: 5%;
}

.add_btn {
    width: 120px;
    height: 40px;
    padding: 10px;
    border-radius: 4px;
    border: none;
    outline: none;
    background: #f69110;
    color: #ffffff;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
   /* margin-left: 40%; */
    /* text-decoration: white; */
  }
  

  .add_checkbox {
    border-radius: 4px;
  }

  .add_span{
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    gap: 5px;
  
  }