.logo{
 display: flex;
width: 155px;
height: auto;
}

.header{
display: flex;
align-items: center;
padding: 10px 20px 10px 20px;
border-bottom: 1px;
gap: 10%;
box-shadow: 0px 4px 10px 0px #0000000D;
}

@media screen and (max-width: 1120px) {
    .header{
        width: 100%;
    }
    
}

.icons_box{
display: flex;
gap: 3%;
}

.icons_left{
    padding-left: 5%;
    display: flex;
    align-items: center;
    gap: 3%;
    flex: 1 1 5%;
}


